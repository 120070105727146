/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  setAboutUsModalVal,
  useAuthActions,
  useAuthDispatch,
  useAuthState
} from '../../contexts/AuthContext'
import { Menu, Layout, Typography, Divider } from 'antd'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  UserAddOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { ReactComponent as GreenhouseEstimator } from '../../stories/assets/greenhouseEstimator.svg'
import GreenhouseDemo from '../../stories/assets/GreenhouseDemo.svg'
import {
  MenuWrapper,
  GreenhouseWrapper,
  JobTitle,
  JobPageHeader,
  AboutUsDetails,
  Dot,
  CustomMenuItemStyle
} from './style'
import { useTheme } from 'styled-components'
import Calculator from '../../stories/assets/calculator.png'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../constants/constants'
import FirstTimeUser from '../../pages/jobs/components/JobRequisitionsSection/JobsTable/components/FirstTimeUserModals'
import moment from 'moment'
import { demoSlidesItems } from '../../pages/whats-new/data/data'
import { getLogoAndText } from '../../helper/index'

const { Content, Header, Footer } = Layout
const { Text } = Typography

const HeaderLayout = ({ children, title, extra, isFooterHide, isZendesk }) => {
  const theme = useTheme()
  const [popUpValue, setPopUpValue] = useState(false)
  const { pathname } = useLocation()
  const history = useHistory()

  const { user, flag, releaseData } = useAuthState()
  const dispatch = useAuthDispatch()
  const actions = useAuthActions()
  const { t } = useTranslation()
  // const environment = getEnvironment(process.env.REACT_APP_BASE_URL)
  // const logo = getLogo(environment)
  const handleLogoutClick = () => {
    actions.logoutUser()
    history.push('/')
  }

  const renderMenus = () => {
    const menus = [
      { path: '/score', name: t(allConstants.ActiveJobs) },
      { path: '/candidates', name: t(allConstants.SearchPassiveCandidates) },
      { path: '/reports', name: t(allConstants.Reports) }
    ]

    const renderMenu = (menu) => (
      <Menu.Item className='menu_items' key={menu.path} style={{ paddingBottom: 7 }}>
        <NavLink to={menu.path}>
          <div>
            <span className="menu_text">{menu.name}</span>
          </div>
        </NavLink>
      </Menu.Item>
    )
    return menus.map(renderMenu)
  }

  const getPathname = () => [`/${pathname.split('/')[1]}`]
  const renderTitle = () =>
    user?.fullName && (
      <>
        <Text> {user?.fullName} </Text>
        <DownOutlined style={{ marginBottom: '0', fontSize: '12px' }} />
      </>
    )

  const renderNavBar = () => {
    return (
      <>
        <MenuWrapper theme={theme} style={{ width: '100%' }}>
          <Menu
            mode="horizontal"
            selectedKeys={getPathname()}
            style={{ width: '75%' }}
            className='main_menu'
          >
            <Menu.Item className='logo_menu' onClick={() => history.push('/homepage')}>
              <img
                src={getLogoAndText(theme.logo).logo}
                style={{ height: 60, marginBottom: 5 }}
                alt="this way global"
              />
            </Menu.Item>
            {renderMenus()}
          </Menu>
          <Menu className="menu-right" mode="horizontal">
            <Menu.SubMenu className='question_submenu' key="sub1" icon={<QuestionCircleOutlined />}>
              <Menu.Item onClick={() => history.push('/whats-new')}>
                <GreenhouseWrapper className='item'>
                  <Dot></Dot>
                  {t(allConstants.WHATS_NEW)}
                </GreenhouseWrapper>
              </Menu.Item>
              <Menu.Item onClick={() => setPopUpValue(true)}>
                <GreenhouseWrapper>
                  {t(allConstants.QUICK_PRODUCT_DEMO)}
                </GreenhouseWrapper>
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/help-center')}>
                <GreenhouseWrapper>
                  {t(allConstants.HelpCenter)}
                </GreenhouseWrapper>
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/tickets')}>
                <GreenhouseWrapper>
                  {t(allConstants.TicketCenter)}
                </GreenhouseWrapper>
              </Menu.Item>
              <Menu.Item onClick={() => dispatch(setAboutUsModalVal(true))}>
                <GreenhouseWrapper>
                  {t(allConstants.ABOUT_US)}
                </GreenhouseWrapper>
              </Menu.Item>
             <CustomMenuItemStyle style={{ height: '70px' }} >
             <AboutUsDetails>
                <div className='item'>
                {t(allConstants.VERSION)}: {releaseData?.versionId?.slice(0, 5)}
                </div>
                <div className='item'>
                {t(allConstants.RELEASE_DATE)}: {moment(releaseData?.versionId?.slice(6, 14)).format('MMMM DD, YYYY') || '-'}
                </div>
             </AboutUsDetails>
             </CustomMenuItemStyle>
            </Menu.SubMenu>
            <Menu.SubMenu
              key="sub2"
              className='user_submenu'
              icon={<UserOutlined />}
              title={renderTitle()}
            >
              {user?.roles?.includes('deladmin') && (
                <Menu.Item onClick={() => history.push('/newcircacustomer')}>
                  <UserAddOutlined /> {t(allConstants.CREATE_CUSTOMER)}
                </Menu.Item>
              )}
              <Menu.Item onClick={() => history.push('/settings/balances')}>
                <GreenhouseWrapper>
                  <SettingOutlined /> {t(allConstants.ACCOUNT_INFO)}
                </GreenhouseWrapper>
              </Menu.Item>
              {flag?.showAtsValue && (
                <Menu.Item onClick={() => history.push('/valuecalculator')}>
                  <img src={Calculator} height={15} width={15} />
                  <GreenhouseWrapper marginLeft={true}>
                    {t(allConstants.TWG_CALCULATOR)}
                  </GreenhouseWrapper>
                </Menu.Item>
              )}
              {flag?.showAtsValue && (
                <Menu.Item onClick={() => history.push('/thirtyMinutes')}>
                  <img src={GreenhouseDemo} />

                  <GreenhouseWrapper marginLeft={true}>
                    {t(allConstants.GREENHOUSE_DEMO)}
                  </GreenhouseWrapper>
                </Menu.Item>
              )}
              {flag?.showAtsEstimator && (
                <Menu.Item onClick={() => history.push('/greenhouseEstimator')}>
                  <GreenhouseEstimator />
                  <GreenhouseWrapper marginLeft={true}>
                    {t(allConstants.GREEN_HOUSE_ESTIMATOR)}
                  </GreenhouseWrapper>
                </Menu.Item>
              )}

              <Menu.Item onClick={handleLogoutClick}>
                <GreenhouseWrapper>
                  <LogoutOutlined /> {t(allConstants.LOGOUT)}
                </GreenhouseWrapper>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </MenuWrapper>
        <FirstTimeUser
          popUpValue={popUpValue}
          setPopUpValue={setPopUpValue}
          closable={true}
          otherSlidesItems={demoSlidesItems}
        />
      </>
    )
  }

  return (
    <Layout>
      <Header
        theme="light"
        style={{
          backgroundColor: '#fff',
          height: 80,
          display: 'flex',
          alignItems: 'flex-end'
        }}
      >
        {renderNavBar()}
      </Header>

      <Content style={{ padding: '16px', minHeight: 'calc(100vh - 430px)' }}>
        {title && (
          <JobPageHeader>
            <JobTitle>
              <span>{title}</span>
            </JobTitle>
            <div>{extra}</div>
          </JobPageHeader>
        )}
        <Layout className="site-layout-background" style={{ padding: '8px 0' }}>
          <Content style={{ padding: '0 0 0 0px' }}>{children}</Content>
        </Layout>
      </Content>
      {!isFooterHide && (
        <Footer style={{ textAlign: 'center' }}>
          {t('ThisWay Global')} ©{new Date().getFullYear()}
        </Footer>
      )}
    </Layout>
  )
}

HeaderLayout.defaultProps = {
  isZendesk: false
}

HeaderLayout.propTypes = {
  children: PropTypes.node,
  extra: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isFooterHide: PropTypes.bool,
  isZendesk: PropTypes.bool
}

export default HeaderLayout
