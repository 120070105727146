/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import moment from 'moment'
import { notification, message, Modal, Radio } from 'antd'
import dayjs from 'dayjs'
import ThiswayDemo from '../assets/thisway-demo.png'
import ThiswayDev from '../assets/thisway-score.png'
import Thisway from '../assets/popup_logo.png'
import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { allConstants } from '../constants/constants'
import fileDownload from 'js-file-download'
const { confirm } = Modal
export const languageFormatFunc = (lang) => {
  const languageCode = lang.slice(0, 2)
  switch (languageCode) {
    case 'en':
      return { language: 'English', code: languageCode, standardCode: lang }
    case 'es':
      return { language: 'Spanish', code: languageCode, standardCode: lang }
    case 'fr':
      return { language: 'French', code: languageCode, standardCode: lang }
    default:
      return { language: 'English', code: 'en', standardCode: 'en_US' }
  }
}

export const handleCsvDownload = async (data, DownloadRef, fileName) => {
  try {
    const jsonData = JSON.stringify(data)
    const blob = new Blob([jsonData], { type: 'text/csv;charset=utf-8' })
    const url = window.URL.createObjectURL(blob)
    DownloadRef.current.href = url
    DownloadRef.current.download = fileName
    await DownloadRef.current.click()
    return true
  } catch (e) {
    notification.error({
      placement: 'topLeft',
      message: 'Something went wrong'
    })
    return false
  }
}

export const fileDownloadDate = () => {
  return dayjs().format('YYMMDD')
}

export function DateFormat (dateStr) {
  return moment(dateStr).format('MM/DD/YYYY')
}

export function DateTime (date) {
  const dateObj = new Date(date)
  const datetime =
    dateObj.toLocaleString('default', { month: 'short' }) +
    ' ' +
    dateObj.getDate() +
    ', ' +
    dateObj.getFullYear() +
    ', ' +
    dateObj.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
  return datetime
}

export const getLogoAndText = (logo) => {
  if (logo) return { logo }
  if (process.env.REACT_APP_BASE_URL.includes('dev')) {
    return { logo: ThiswayDev, text: '- Dev' }
  }
  if (process.env.REACT_APP_BASE_URL.includes('demo')) {
    return { logo: ThiswayDemo, text: ' - Demo' }
  }
  return { logo: Thisway }
}

export const handleDateChecker = (startDate, endDate) => {
  const isStartDateToday = dayjs().isSame(dayjs(startDate), 'day')
  const isEndDateAfterToday = dayjs(endDate).isAfter(dayjs(), 'day')
  return isStartDateToday && isEndDateAfterToday
}

export const dateInCardFormat = (date) => {
  return dayjs(date).format('MMM D, YYYY')
}

export const getVideoIdFromUrl = (url) => {
  const videoId = url.split('/embed/')[1].split('?')[0]
  return videoId
}
export const getThumbnailUrl = (url) => {
  if (!url || !url.includes('embed')) return ''
  const videoId = getVideoIdFromUrl(url)
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
}
export const generateFileName = (prefix, title) => {
  return `${prefix}-${title
    ?.toLowerCase()
    ?.replace(/\s/g, '_')}-${fileDownloadDate()}.csv`
}

export const handleConfirmResumeForPassive = (payload, exportApi, name) => {
  const csvPayload = { withCv: false }
  const handleChange = (e) => {
    // debugger
    const { value } = e.target
    csvPayload.withCv = value
    console.log('csv again', csvPayload)
  }
  console.log('csv', csvPayload)
  return confirm({
    title: 'Run candidates’ email bounce check?',
    okText: 'Submit',
    cancelText: 'Cancel',
    wrapClassName: 'confirmPassiveModal',
    content: (
      <div>
        <Radio.Group style={{ display: 'flex', justifyContent: 'flexStart', gap: '10px', flexDirection: 'row' }} onChange={handleChange}>
          <Radio value={true}>Yes (Candidates CSV will be emailed shortly)</Radio>
          <Radio value={false}>No (Candidates CSV will be downloaded immediately)</Radio>
        </Radio.Group>
      </div>
    ),
    icon: <ExclamationCircleOutlined />,
    onOk: async () => {
      const response = await exportApi({}, { ...payload, csvPayload })
      if (csvPayload.withCv) {
        message.success(allConstants.RECIEVE_EMAIL_LINK)
      } else {
        fileDownload(response.data, name)
      }
    }
    // onCancel: async () => {
    //   const response = await exportApi(
    //     {},
    //     { ...payload, csvPayload }
    //   )
    // }
  })

  // export const handleConfirmResumeForPassive = (payload, exportApi, name, t) => {
  //   return confirm({
  //     title: t(allConstants.INCLUDE_CSV),
  //     okText: t(allConstants.YES),
  //     cancelText: t(allConstants.NO),
  //     icon: <ExclamationCircleOutlined />,
  //     onOk: async () => {
  //       await exportApi({}, { ...payload, withCv: true })
  //       message.success(t(allConstants.RECIEVE_EMAIL_LINK))
  //     },
  //     onCancel: async () => {
  //       const response = await exportApi({}, { ...payload, withCv: false })
  //       fileDownload(response.data, name)
  //     }
  //   })
}

export const handleConfirmResumeForDirect = (payload, exportApi, name, t) => {
  return confirm({
    title: t(allConstants.INCLUDE_RESUME),
    okText: t(allConstants.YES),
    cancelText: t(allConstants.NO),
    wrapClassName: 'confirmPassiveModal',
    icon: <ExclamationCircleOutlined />,
    onOk: async () => {
      await exportApi({ withcv: true }, { ...payload })
      message.success(t(allConstants.RECIEVE_EMAIL_LINK))
    },
    onCancel: async () => {
      const response = await exportApi({ withcv: false }, { ...payload })
      fileDownload(response.data, name)
    }
  })
}
export default {
  handleCsvDownload
}
