/* eslint-disable no-debugger */
import React from 'react'
import { message } from 'antd'
// import { useRequest } from 'ahooks'
import { Switch } from 'react-router'
// Routes
import PrivateRoute from '../../../components/PrivateRoute'
// Local Files
// import Candidates from '../SearchCandidate'
// Controller
import FilteredCandidates from '../SearchedCandidate'
import * as discoverApi from '../../../api-twg/discover/workbook-controller'
import * as discoverJobController from '../../../api-twg/discover/discover-job-controller'
// import * as queryController from '../../../api-twg/discover/query-controller'
import * as userController from '../../../api-twg/twg/user-controller'
import * as searchController from '../../../api-twg/discover/search-controller'
import * as enrichController from '../../../api-twg/discover/enrichment-controller'
import * as exportController from '../../../api-twg/discover/export-controller'
import SearchBar from '../SearchBar/SearchBar'

// Context Api instance
const QueryActionsContext = React.createContext(undefined, undefined)
const QueryStateContext = React.createContext(undefined, undefined)
const QueryDispatchContext = React.createContext(undefined, undefined)

// Actions Types
export const SET_QUERIES = 'SET_QUERIES'
export const CANDIDATES_FILTERS = 'CANDIDATES_FILTERS'
export const SET_QUERY_DELETE = 'SET_QUERY_DELETE'
export const SET_QUERY_UPDATE = 'SET_QUERY_UPDATE'
export const SET_QUERY_DUPLICATE = 'SET_QUERY_DUPLICATE'
export const SET_SEARCH_CANDIDATE_SCREEN_TOGGLE = 'SET_SEARCH_CANDIDATE_SCREEN_TOGGLE'
export const SET_FIELDS = 'SET_FIELDS'
export const IS_LOADING = 'IS_LOADING'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_DISCOVER_JOB_STATS = 'SET_DISCOVER_JOB_STATS'
// User Controller
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS'
// Enrich Controller
export const SET_PROFILE_VIEW = 'SET_PROFILE_VIEW'
export const SET_SEARCH_BAR_VALUES = 'SET_SEARCH_BAR_VALUES'
export const SET_SEARCH_API_OBJ = 'SET_SEARCH_API_OBJ'
export const SET_SAVED_SEARCH_VAL = 'SET_SAVED_SEARCH_VAL'

// Actions Creators
export const setIsLoading = (bool) => ({ type: IS_LOADING, bool })
// Queries
export const setQueries = (queries) => ({ type: SET_QUERIES, queries })
export const setCandidatesFilters = (payload) => ({ type: CANDIDATES_FILTERS, payload })
export const setQueryDelete = (deleteQuery) => ({ type: SET_QUERY_DELETE, deleteQuery })
export const setQueryUpdate = (updateJob) => ({ type: SET_QUERY_UPDATE, updateJob })
export const setQueryDuplicate = (deleteQuery) => ({ type: SET_QUERY_DUPLICATE, deleteQuery })
export const setSearchCandidateScreenToggle = (payload) => ({ type: SET_SEARCH_CANDIDATE_SCREEN_TOGGLE, payload })
export const setFields = (payload) => ({ type: SET_FIELDS, payload })
export const setSearch = (payload) => ({ type: SET_SEARCH, payload })
export const setDiscoverJobStats = (stats) => ({ type: SET_DISCOVER_JOB_STATS, stats })
// User Controller
export const setTeamMember = (teamMembers) => ({ type: SET_TEAM_MEMBERS, teamMembers })
// Enrich Controller
export const setProfileView = (view) => ({ type: SET_PROFILE_VIEW, view })
export const setSearchBarValue = (searchBarValues) => ({ type: SET_SEARCH_BAR_VALUES, searchBarValues })
export const setSearchApiObj = (searchApiObj) => ({ type: SET_SEARCH_API_OBJ, searchApiObj })
export const setSavedSearchVal = (savedSearchVal) => ({ type: SET_SAVED_SEARCH_VAL, savedSearchVal })

const initialState = {
  // Commons
  isLoading: false,
  // Query
  queries: [],
  queryPreview: undefined,
  SearchCandidateScreenToggle: true,
  SelectedCandidate: undefined,
  SelectedCandidates: [],
  candidatesFilters: undefined,
  searchedCandidates: undefined,
  updatedJobData: undefined,
  selectedFields: [],
  discoverJobStats: [],
  // user Controller
  teamMembers: [],
  // Enrich Controller
  profileView: undefined,
  searchBarValues: {
    title: '',
    location: ''
  },
  searchApiObj: {
    _v: 1.1,
    clientRequestId: null,
    mustHave: {
      career_length: '',
      career_count: '',
      location: '',
      postCode: null,
      distance: 60,
      school: []
    },
    shouldHave: {
      expertise: [],
      summary: [],
      certifications: [],
      current_company: [],
      current_title: [],
      current_industry: [],
      previous_company: [],
      previous_title: [],
      previous_industry: [],
      school: [],
      degree: [],
      languages: []
    },
    mustNotHave: {
      degree: [],
      certifications: [],
      school: []
    }
  },
  savedSearchVal: false
}

export function reducer (state, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.bool
      }
    case SET_QUERIES:
      return {
        ...state,
        queries: action.queries
      }
    case CANDIDATES_FILTERS:
      return {
        ...state,
        candidatesFilters: action.payload
      }
    case SET_QUERY_DELETE:
      return {
        ...state
      }
    case SET_SEARCH_CANDIDATE_SCREEN_TOGGLE:
      return {
        ...state,
        SearchCandidateScreenToggle: action.payload
      }
    case SET_SEARCH:
      return {
        ...state,
        searchedCandidates: action.payload
      }
    case SET_FIELDS:
      return {
        ...state,
        ...action.payload
      }
    case SET_QUERY_UPDATE:
      return {
        ...state,
        candidatesFilters: action.updateJob
      }
    case SET_DISCOVER_JOB_STATS:
      return {
        ...state,
        discoverJobStats: action.stats
      }
      // User Controller
    case SET_TEAM_MEMBERS:
      return {
        ...state,
        ...action.teamMembers
      }
    // Enrich Controller
    case SET_PROFILE_VIEW:
      return {
        ...state,
        profileView: action.view
      }

    case SET_SEARCH_BAR_VALUES:
      return {
        ...state,
        searchBarValues: action.searchBarValues
      }

    case SET_SEARCH_API_OBJ:
      return {
        ...state,
        searchApiObj: action.searchApiObj
      }

    case SET_SAVED_SEARCH_VAL:
      return {
        ...state,
        savedSearchVal: action.savedSearchVal
      }

    default:
      return state
  }
}

export const createWorkbook = (dispatch) => async (payload) => {
  try {
    const response = await discoverApi.createWorkbook(payload)
    const { data } = response || {}
    return data
  } catch (e) {
    // message.error('Something went wrong with create query')
    console.log(e.message)
  }
}

export const createJob = (dispatch) => async (payload) => {
  try {
    const response = await discoverJobController.createJob(payload)
    const { data } = response || {}
    dispatch(setCandidatesFilters(data))
    return data
  } catch (e) {
    message.error('Something went wrong with create query')
    console.log(e.message)
  }
}

export const getCandidatesFromSearch = (dispatch) => async (payload) => {
  dispatch(setIsLoading(true))
  try {
    const { data } = await discoverJobController.getCandidatesFromSearch(payload)
    dispatch(setSearch(data))
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getDiscoverJobs = (dispatch) => async () => {
  // const response = await discoverApi.getWorkbookQueries()
  const jobs = await discoverJobController.getDiscoverJobs()
  const { data } = jobs || {}
  data.reverse()
  dispatch(setQueries(data))
  return data
}

export const jobPreview = (dispatch) => async (jobKey) => {
  dispatch(setIsLoading(true))
  try {
    const response = await discoverJobController.getDiscoverJob(jobKey)
    const { data } = response || {}
    dispatch(setCandidatesFilters(data))
    return data
  } catch (err) {
    console.log(err.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getQueryPreviewStats = (dispatch) => async (workbookId, queryId) => {
  const response = await searchController.getPreview(workbookId, queryId)
  const { data } = response || {}
  // dispatch(setQueryPreview(data))
  return data
}

export const getSearchedCandidates = (dispatch) => async (jobKey) => {
  dispatch(setIsLoading(true))
  try {
    const response = await searchController.getDiscoverSearch(jobKey)
    const { data } = response || {}
    dispatch(setSearch(data))
    return data
  } catch (e) {
    dispatch(setSearch(undefined))
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const deleteJob = (dispatch) => async (payload) => {
  const response = await discoverJobController.deleteJob(payload)
  const { data } = response || {}
  dispatch(setQueryDelete(data))
}

export const updateJob = (dispatch) => async (payload) => {
  dispatch(setIsLoading(true))
  try {
    const response = await discoverJobController.updateJob(payload)
    const { data } = response || {}
    dispatch(setDiscoverJobStats(data))
  } catch (e) {
    console.log(e.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const discoverJobStats = (dispatch) => async (jobId) => {
  dispatch(setIsLoading(true))
  try {
    const response = await discoverJobController.discoverJobStats(jobId)
    const { data } = response || {}
    dispatch(setDiscoverJobStats(data))
  } catch (e) {
    console.log(e.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

// User Controller
export const getTeamMember = (dispatch) => async () => {
  const response = await userController.getTeamMembers()
  const { data } = response || {}
  dispatch(setTeamMember(data))
}

// Enrich Controller
export const enrichData = (dispatch) => async payload => {
  // dispatch(setIsLoading(true))
  try {
    const response = await enrichController.doEnrichment(payload)
    const { data } = response || {}
    dispatch(setFields({ profileView: data }))
    return response
  } catch (e) {
    console.log(e)
    dispatch(setIsLoading(false))
  } finally {
    // dispatch(setIsLoading(false))
  }
}

// Export Controller
export const exportCandidates = (dispatch) => async (params, data) => {
  return await exportController.doExport(params, data)
}

function CandidatesContextProvider () {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    // Commons
    setIsLoading: setIsLoading(dispatch),
    // Query
    createJob: createJob(dispatch),
    getDiscoverJobs: getDiscoverJobs(dispatch),
    jobPreview: jobPreview(dispatch),
    deleteJob: deleteJob(dispatch),
    updateJob: updateJob(dispatch),
    discoverJobStats: discoverJobStats(dispatch),
    // duplicateQuery: duplicateQuery(dispatch),
    setCandidatesFilters: setCandidatesFilters(dispatch),
    createWorkbook: createWorkbook(dispatch),
    // Search Controller
    getQueryPreviewStats: getQueryPreviewStats(dispatch),
    getSearchedCandidates: getSearchedCandidates(dispatch),
    // Enrich Controller
    enrichData: enrichData(dispatch),
    // Export Controller
    exportCandidates: exportCandidates(dispatch),
    getCandidatesFromSearch: getCandidatesFromSearch(dispatch)
  })

  // useRequest(async () => actions.getDiscoverJobs())

  return (
        <QueryActionsContext.Provider value={actions}>
            <QueryStateContext.Provider value={state}>
                <QueryDispatchContext.Provider value={dispatch}>
                   <Switch>
                        <PrivateRoute
                        path={'/candidates/:jobkey'}
                        strict={false}
                        component={FilteredCandidates}
                        />
                        <PrivateRoute
                        path={'/simplesearch/:title/:skills/:location'}
                        strict={false}
                        component={FilteredCandidates}
                        />
                         <PrivateRoute
                        path={'/candidates'}
                        strict={false}
                        component={SearchBar}
                        />
                    </Switch>
                </QueryDispatchContext.Provider>
            </QueryStateContext.Provider>
        </QueryActionsContext.Provider>
  )
}

// Hooks
function useQueryActions () {
  const context = React.useContext(QueryActionsContext)
  if (context === undefined) {
    throw new Error('useQueryActions must be used within a QueryContextProvider')
  }
  return context
}

function useQuerysState () {
  const context = React.useContext(QueryStateContext)
  if (context === undefined) {
    throw new Error('useQueryState must be used within a QueryContextProvider')
  }
  return context
}

function useQuerysDispatch () {
  const context = React.useContext(QueryDispatchContext)
  if (context === undefined) {
    throw new Error('useQueryDispatch must be used within a QueryContextProvider')
  }
  return context
}

export { CandidatesContextProvider, useQueryActions, useQuerysState, useQuerysDispatch }
