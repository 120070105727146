/* eslint-disable no-debugger */
import React from 'react'
import { Row, Form, Input, Col } from 'antd'
import PropTypes from 'prop-types'
// Local Files
import FiltersLayout from '../component/FiltersLayout'
import AddMore from '../component/AddMore'
import TagGroup from '../../../components/TagGroup'
// helper
import { UpdateActiveCandidateData } from '../../../helpers/QueryHelpers'
import HandleInputTags from '../../../helpers/HandleInputTagsHelpers'
import { setIsOpen } from '../helpers/actionHelpers'
import FiltersCount from '../component/FiltersCount'
import { setSearchApiObj, useQuerysDispatch, useQuerysState } from '../../../CandidateContext/SearchQueryContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'
const { EXCLUDE_SCHOOL, SCHOOL, SCHOOL_PLACEHOLDER, EXCLUDE_SCHOOL_PLACEHOLDER } = allConstants
export default function Education ({
  contextData, existedEducation, setExistedFilters,
  match, existedFilters, setSearchThroughAdvanceFilter,
  setSearchApiVal
}) {
  const [values, setValues] = React.useState({
    degree: [],
    certifications: [],
    degreeValue: '',
    certificationsValue: '',
    excludeCertsValue: '',
    excludeDegreeValue: '',
    excludedDegree: [],
    excludeedCertifications: [],
    isExcludeCerts: false,
    isExcludeDegree: false,
    isOpen: false,
    isExcludeSchool: false,
    school: [],
    excludedSchools: [],
    excludeSchoolValue: '',
    schoolValue: ''

  })
  const dispatch = useQuerysDispatch()
  const { searchApiObj } = useQuerysState()
  const [advancedSearchFilter, setAdvancedSearchFilter] = React.useState(false)
  const { t } = useTranslation()
  const { candidatesFilters, api, matchingResults } = contextData
  const handleInputChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleInpurConfirm = async (activeInputValue, activeInput) => {
    setAdvancedSearchFilter(true)
    const tags = HandleInputTags(activeInputValue, activeInput, values, setValues)
    if (tags.length > 0 && values[activeInputValue]) {
      setValues({
        ...values,
        [activeInput]: tags,
        [activeInputValue]: '',
        isExcludeCerts: false,
        isExcludeDegree: false,
        isExcludeSchool: false
      })
      updateJobApi(activeInput, tags)
    }
  }

  const handleDelete = async (tags, activeTagsType) => {
    const filteredTags = { ...values, [activeTagsType]: tags }
    setValues(filteredTags)
    if (match) {
      setAdvancedSearchFilter(true)
    }
    updateJobApi(activeTagsType, tags)
  }

  const updateJobApi = React.useCallback((activeInput, tags) => {
    const degree = activeInput === 'degree' ? tags : values.degree
    const excludeDegree = activeInput === 'excludedDegree' ? tags : values.excludedDegree
    const certifications = activeInput === 'certifications' ? tags : values.certifications
    const excludeCertification = activeInput === 'excludeedCertifications' ? tags : values.excludeedCertifications
    const excludeSchools = activeInput === 'excludedSchools' ? tags : values.excludedSchools
    const school = activeInput === 'school' ? tags : values.school
    const getSelectedEducation = (input) => {
      const filterEducation = {
        degree: { degree, degree_exclude: excludeDegree },
        excludedDegree: { degree, degree_exclude: excludeDegree },
        school: { school, school_exclude: excludeSchools },
        excludedSchools: { school, school_exclude: excludeSchools },
        certifications: { certification: certifications, certification_exclude: excludeCertification },
        excludeedCertifications: { certification: certifications, certification_exclude: excludeCertification }
      }
      return filterEducation[input]
    }
    UpdateActiveCandidateData({
      mustHave: { school },
      shouldHave: {
        degree,
        certifications
      },
      mustNotHave: {
        degree: excludeDegree,
        certifications: excludeCertification,
        school: excludeSchools
      }
    },
    candidatesFilters,
    api,
    getSelectedEducation(activeInput),
    matchingResults,
    match

    )
  }, [values])

  React.useEffect(() => {
    setValues({
      ...values,
      degree: existedEducation?.degree?.shouldHave || [],
      excludedDegree: existedEducation?.degree?.mustNotHave || [],
      certifications: existedEducation?.certifications?.shouldHave || [],
      excludeedCertifications: existedEducation?.certifications?.mustNotHave || [],
      school: existedEducation?.school?.mustHave || [],
      excludedSchools: existedEducation?.school?.mustNotHave || []
    })
  }, [existedEducation])
  React.useEffect(() => {
    if (match && advancedSearchFilter) {
      setExistedFilters({
        ...existedFilters,
        education: {
          ...existedFilters.education,
          school: {
            mustHave: values.school || [],
            mustNotHave: values.excludedSchools || [],
            shouldHave: []
          },
          certifications: {
            mustHave: [],
            mustNotHave: values?.excludeedCertifications || [],
            shouldHave: values?.certifications || []
          },
          degree: {
            mustHave: [],
            mustNotHave: values?.excludedDegree || [],
            shouldHave: values?.degree || []
          }

        }
      })
      setSearchThroughAdvanceFilter(true)
      setAdvancedSearchFilter(false)
      setSearchApiVal(true)
    }
  }, [advancedSearchFilter, values])
  const clearAllFilters = () => {
    UpdateActiveCandidateData({
      shouldHave: {
        degree: [],
        certifications: []
      }
    },
    candidatesFilters,
    api,
    {
      school: [],
      degree: [],
      degree_exclude: [],
      certification: [],
      certificaiton_exclude: []
    },
    matchingResults
    )
  }

  const handleDegreeIncludeFilters = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        shouldHave: { ...searchApiObj.shouldHave, degree: [] }
      }
    ))
    setValues({
      ...values,
      degree: []
    })
    setAdvancedSearchFilter(true)
  }
  const handleSchoolIncludeFilters = () => {
    if (match) {
      dispatch(setSearchApiObj(
        {
          ...searchApiObj,
          mustHave: { ...searchApiObj.mustHave, school: [] }
        }
      ))
      setValues({
        ...values,
        school: []
      })
      setAdvancedSearchFilter(true)
    } else {
      UpdateActiveCandidateData({ mustHave: { school: [] } }, candidatesFilters, api, { school: [] }, matchingResults)
    }
  }

  const handleDegreeExcludeFilters = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        mustNotHave: { ...searchApiObj.mustNotHave, excludedDegree: [] }

      }
    ))
    setValues({
      ...values,
      excludedDegree: []
    })
    setAdvancedSearchFilter(true)
  }
  const handleSchoolExcludeFilters = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        mustNotHave: { ...searchApiObj.mustNotHave, school: [] }

      }
    ))
    setValues({
      ...values,
      excludedSchools: []
    })
    setAdvancedSearchFilter(true)
  }

  const handleCertificationsIncludeFilter = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        shouldHave: { ...searchApiObj.shouldHave, certifications: [] }
      }
    ))
    setValues({
      ...values,
      certifications: []
    })
    setAdvancedSearchFilter(true)
  }

  const handleCertificationsExcludeFilter = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        mustNotHave: { ...searchApiObj.mustNotHave, excludeedCertifications: [] }
      }
    ))
    setValues({
      ...values,
      excludeedCertifications: []
    })
    setAdvancedSearchFilter(true)
  }

  return (
    <FiltersLayout
      title={t(allConstants.EDUCATION)}
      clearAllFilters={clearAllFilters}
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
    >
      {/* school */}
      <Row>
      <Form className='block-level-form'>
           {(values.school.length > 0 || values.isOpen) &&
            <FiltersCount
              title={`${t(SCHOOL)}:`}
              ExcludeField={values.school}
              clearExcludeFilters={handleSchoolIncludeFilters}
              classNames={'filters-sub-heading'}
            />}
          <Row className='tags-label filters-tags' >
            <TagGroup
              tags={values.school}
              onDelete={handleDelete}
              activeTagsType="school"
            />
          </Row>
          {values.isOpen && <>
          <Form.Item >
            <Input
              placeholder={t(SCHOOL_PLACEHOLDER)}
              name='schoolValue'
              value={values.schoolValue}
              onChange={handleInputChange}
              onBlur={!match ? () => handleInpurConfirm('schoolValue', 'school') : null}
              onPressEnter={() => handleInpurConfirm('schoolValue', 'school')}
            />
          </Form.Item>
          </>}
        </Form>
        </Row>
        <Col className={values.excludedSchools.length > 0 || values.isOpen ? 'exclude-tags-area' : ''}>
        {(values.excludedSchools.length > 0 || values.isExcludeSchool) &&
          <FiltersCount
            title={`${t(EXCLUDE_SCHOOL)}:`}
            ExcludeField={values.excludedSchools}
            clearExcludeFilters={match
              ? () => handleSchoolExcludeFilters()
              : () => UpdateActiveCandidateData({ mustNotHave: { school: [] } }, candidatesFilters, api, { school: [], school_exclude: values.excludedSchools }, matchingResults)}
          />}

        <Row >
          <TagGroup
            tags={values.excludedSchools}
            isExlude={values.excludedSchools && true}
            onDelete={handleDelete}
            activeTagsType="excludedSchools" />
        </Row>

        {values.isExcludeSchool && values.isOpen && <Row>
          <Input
            name='excludeSchoolValue'
            placeholder={t(EXCLUDE_SCHOOL_PLACEHOLDER)}
            value={values.excludeSchoolValue}
            onChange={handleInputChange}
            onBlur={!match ? () => handleInpurConfirm('excludeSchoolValue', 'excludedSchools') : null}
            onPressEnter={() => handleInpurConfirm('excludeSchoolValue', 'excludedSchools')}
            className='filtered-exclude-input'
          />
        </Row>}
      </Col>

      {!values.isExcludeSchool && values.isOpen &&
        <AddMore
          title={t(EXCLUDE_SCHOOL)}
          onClick={() => setValues({ ...values, isExcludeSchool: true })} />}

      {/* Degree  */}
      <Row >
        <Form className='block-level-form' >
          {(values.degree.length > 0 || values.isOpen) &&
            <FiltersCount
              title={`${t(allConstants.DEGREE)}:`}
              ExcludeField={values.degree}
              clearExcludeFilters={
                match
                  ? () => handleDegreeIncludeFilters()
                  : () => UpdateActiveCandidateData({ shouldHave: { degree: [] } }, candidatesFilters, api, { degree: [], degree_exclude: values.excludedDegree }, matchingResults)
              }
              classNames={'filters-sub-heading'}
            />}
          <Row className='tags-label filters-tags' >
            <TagGroup
              tags={values.degree}
              onDelete={handleDelete}
              activeTagsType="degree"
            />
          </Row>
          {values.isOpen && <Form.Item >
            <Input
              placeholder={t(allConstants.ADD_DEGREE)}
              name='degreeValue'
              value={values.degreeValue}
              onChange={handleInputChange}
              onBlur={!match ? () => handleInpurConfirm('degreeValue', 'degree') : null}
              onPressEnter={() => handleInpurConfirm('degreeValue', 'degree')}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col className={values.excludedDegree.length > 0 || values.isOpen ? 'exclude-tags-area' : ''}>
        {(values.excludedDegree.length > 0 || values.isExcludeDegree) &&
          <FiltersCount
            title={`${t(allConstants.EXCLUDED_DEGREE)}:`}
            ExcludeField={values.excludedDegree}
            clearExcludeFilters={match
              ? () => handleDegreeExcludeFilters()
              : () => UpdateActiveCandidateData({ mustNotHave: { degree: [] } }, candidatesFilters, api, { degree: values.degree, degree_exclude: [] }, matchingResults)}
          />}

        <Row >
          <TagGroup
            tags={values.excludedDegree}
            isExlude={values.excludedDegree && true}
            onDelete={handleDelete}
            activeTagsType="excludedDegree" />
        </Row>

        {values.isExcludeDegree && values.isOpen && <Row>
          <Input
            name='excludeDegreeValue'
            placeholder={t(allConstants.EXCLUDED_DEGREE)}
            value={values.excludeDegreeValue}
            onChange={handleInputChange}
            onBlur={!match ? () => handleInpurConfirm('excludeDegreeValue', 'excludedDegree') : null}
            onPressEnter={() => handleInpurConfirm('excludeDegreeValue', 'excludedDegree')}
            className='filtered-exclude-input'
          />
        </Row>}
      </Col>

      {!values.isExcludeDegree && values.isOpen &&
        <AddMore
          title={t(allConstants.EXCLUDED_DEGREE)}
          onClick={() => setValues({ ...values, isExcludeDegree: true })} />}

      {/*  Certification */}
      <Row className={(values.certifications.length > 0 || values.isOpen) ? 'margin-top-row' : ''} >
        <Form className='block-level-form' >
          {(values.certifications.length > 0 || values.isOpen) &&
            <FiltersCount
              title={`${t(allConstants.CERTIFICATION)}:`}
              ExcludeField={values.certifications}
              clearExcludeFilters={match
                ? () => handleCertificationsIncludeFilter()
                : () => UpdateActiveCandidateData({ shouldHave: { certifications: [] } }, candidatesFilters, api, { certification: [], certificaiton_exclude: values.excludeedCertifications }, matchingResults)
              }
              classNames={'filters-sub-heading'}
            />}
          <Row className='tags-label filters-tags' >
            <TagGroup
              tags={values.certifications}
              onDelete={handleDelete}
              activeTagsType="certifications"
            />
          </Row>
          {values.isOpen && <Form.Item>
            <Input
              placeholder={t(allConstants.ADD_CERTIFICATION)}
              name='certificationsValue'
              value={values.certificationsValue}
              onChange={handleInputChange}
              onBlur={!match ? () => handleInpurConfirm('certificationsValue', 'certifications') : null}
              onPressEnter={() => handleInpurConfirm('certificationsValue', 'certifications')}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col className={values.excludeedCertifications.length > 0 || values.isOpen ? 'exclude-tags-area' : ''}>
        {(values.excludeedCertifications.length > 0 || values.isExcludeCerts) &&
          <FiltersCount
            title={t(allConstants.EXCLUDED_CERTIFICATION)}
            ExcludeField={values.excludeedCertifications}
            clearExcludeFilters={match
              ? () => handleCertificationsExcludeFilter()
              : () => UpdateActiveCandidateData({ mustNotHave: { certifications: [] } }, candidatesFilters, api, { certification: values.certifications, certificaiton_exclude: [] }, matchingResults)}
          />}

        <Row >
          <TagGroup
            tags={values.excludeedCertifications}
            isExlude={values.excludeedCertifications && true}
            onDelete={handleDelete}
            activeTagsType="excludeedCertifications"
          />
        </Row>

        {values.isExcludeCerts && <Row>
          <Input
            name='excludeCertsValue'
            placeholder={t(allConstants.EXCLUDED_CERTIFICATION)}
            value={values.excludeCertsValue}
            onChange={handleInputChange}
            onBlur={!match ? () => handleInpurConfirm('excludeCertsValue', 'excludeedCertifications') : null}
            onPressEnter={() => handleInpurConfirm('excludeCertsValue', 'excludeedCertifications')}
            className='filtered-exclude-input'
          />
        </Row>}
      </Col>

      {!values.isExcludeCerts && values.isOpen &&
        <AddMore
          title={t(allConstants.EXCLUDED_CERTIFICATION)}
          onClick={() => setValues({ ...values, isExcludeCerts: true })} />}

    </FiltersLayout>
  )
}

Education.propTypes = {
  contextData: PropTypes.object.isRequired,
  existedEducation: PropTypes.object,
  setExistedFilters: PropTypes.func,
  match: PropTypes.object,
  existedFilters: PropTypes.object,
  setSearchThroughAdvanceFilter: PropTypes.func,
  setSearchApiVal: PropTypes.func
}
