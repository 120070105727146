/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Modal, Button, message } from 'antd'
import * as scoreExportApi from '../../../api-twg/score/score-controller'
import * as discoverExportApi from '../../../api-twg/discover/export-controller'
import { DollarCircleTwoTone, ExportOutlined } from '@ant-design/icons'
import { useJobRequisitionsState } from '../context/ApplicantsContextProvider'
import { useRequest, useToggle } from 'ahooks'
import { useAuthState } from '../../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'
import { useJobsState } from '../../jobs/context/JobsContext'
import { fileDownloadDate, generateFileName, handleConfirmResumeForDirect, handleConfirmResumeForPassive } from '../../../helper'
import CandidateExportModal from '../../../components/CandidateExportModal/CandidateExportModal'
const { confirm } = Modal
export default function ExportButton ({ selectedIds, resultSetId }) {
  const { t } = useTranslation()
  const { filter } = useJobRequisitionsState()
  const [data, setData] = useState(false)
  const [candidateExportModal, setCandidateExportModal] = useState({ directModal: false, passiveModal: false })
  const { selectedJob } = useJobsState()
  const { title } = selectedJob || {}
  const [loading, { toggle }] = useToggle(false)
  const { user } = useAuthState()

  const { run: runScoreExport } = useRequest((params, data) => scoreExportApi.doExport(params, data), { manual: true })
  const { run: runDiscoverExport } = useRequest((params, data) => discoverExportApi.doExport(params, data), { manual: true })

  const exportingFromActiveJobTable = async () => {
    if (filter === 'passive') {
      handleCandidatePassiveModal(true)
      // handleConfirmResumeForPassive(data, runDiscoverExport, generateFileName('passive-candidates-exports-for', title), t)
    } else {
      handleCandidateDirectModal(true)
      // handleConfirmResumeForDirect(data, runScoreExport, generateFileName('direct-applicants-exports-for', title), t)
    }
  }

  const handleExportClick = async () => {
    const data = { resultIds: selectedIds, resultSetId: resultSetId }
    if (filter === 'passive' && user?.showConsumptionPopups) {
      confirm({
        width: '600px',
        title: t(allConstants.EXPORT_SELECTED_CANDIDATES),
        okText: t(allConstants.YES),
        cancelText: t(allConstants.NO),
        icon: <DollarCircleTwoTone />,
        content: t(allConstants.REQUIRE_CREDIT),
        onOk: () => {
          setData(data)
          exportingFromActiveJobTable()
        },
        onCancel: () => { }
      })
    } else {
      setData(data)
      exportingFromActiveJobTable()
    }
  }
  const handleCandidateDirectModal = (val) => {
    setCandidateExportModal(prev => ({ ...prev, directModal: val }))
  }
  const handleCandidatePassiveModal = (val) => {
    setCandidateExportModal(prev => ({ ...prev, passiveModal: val }))
  }
  // const handleOkExport = () => {

  // // handleConfirmResumeForPassive(data, runDiscoverExport, generateFileName('passive-candidates-exports-for', title), t)
  // }
  const handleOkPassiveExport = async (csvPayload) => {
    try {
      toggle()
      const response = await runDiscoverExport(
        {},
        {
          ...data,
          ...csvPayload
        }
      )
      if (csvPayload.withCv) {
        message.success('You will receive an email with the link shortly.')
      } else {
        const fileName = generateFileName(
          'passive-candidates-exports-for',
          title
        )
        fileDownloadDate(response.data, fileName)
      }
      handleCandidatePassiveModal(false)
    } catch (err) {
      console.log('err', err)
    } finally {
      toggle()
    }
  }
  const handleOkDirectExport = async (csvPayload) => {
    // handleConfirmResumeForDirect(data, runScoreExport, generateFileName('direct-applicants-exports-for', title), t)
    try {
      toggle()
      const response = await runScoreExport(
        csvPayload,
        data
      )
      if (csvPayload.withCv) {
        message.success('You will receive an email with the link shortly.')
      } else {
        const fileName = generateFileName(
          'direct-applicants-exports-for',
          title
        )
        fileDownloadDate(response.data, fileName)
      }
      handleCandidatePassiveModal(false)
    } catch (err) {
      console.log('err', err)
    } finally {
      toggle()
    }
  }
  return (
  <>

    <CandidateExportModal
        handleOk={handleOkDirectExport}
        handleCandidateModal={handleCandidateDirectModal}
        visible={candidateExportModal.directModal}
        title={t(allConstants.EXPORT_CANDIDATE_TEXT)}
        loading={loading}
      />
      <CandidateExportModal
        handleOk={handleOkPassiveExport}
        handleCandidateModal={handleCandidatePassiveModal}
        visible={candidateExportModal.passiveModal}
        title={t(allConstants.EXPORT_PASSIVE)}
        loading={loading}
      />
    <Button
      type="primary"
      icon={<ExportOutlined />}
      disabled={!(selectedIds?.length) || loading}
      onClick={handleExportClick}
      loading={loading}
    >
      {t(allConstants.EXPORT)}
    </Button>
  </>
  )
}
