/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react'
import PageHeaderLayoutWithHeader from '../../layouts/PageHeaderLayoutWithHeader'
import { useTranslation } from 'react-i18next'
import { WhatsNewWrapper } from './style'
import { allConstants } from '../../constants/constants'
import WhatsNewItem from './components/WhatsNewItem/WhatsNewItem'
import { getAllWhatNew } from '../../api-twg/score/whats-new-controller'
import useGet from '../../hooks/useGetApi'
import { getThumbnailUrl } from '../../helper'
import { Empty, Spin } from 'antd'
import moment from 'moment'

export const WhatsNew = () => {
  const { t } = useTranslation()
  const { handleGet, response, loading } = useGet()
  const [items, setItems] = useState([])

  useEffect(() => {
    handleGet(getAllWhatNew)
  }, [handleGet])
  useEffect(() => {
    const { status, data } = response || {}
    if (status === 200) {
      const publishedItems = data.reduce((acc, val) => {
        if (val.status === 'Published' &&
        moment(val.notAfter).endOf('day').isSameOrAfter(moment().endOf('day')) &&
        moment(val.notBefore).startOf('day').isSameOrBefore(moment().startOf('day'))) {
          acc.push({
            ...val,
            thumbnailUrl: getThumbnailUrl(val?.videoLink)
          })
        }
        return acc
      }, [])
      setItems(publishedItems)
    }
  }, [loading, response])
  return (
    <PageHeaderLayoutWithHeader title={t(allConstants.WHAT_NEW)}>
      <Spin spinning={loading}>
        <WhatsNewWrapper>
          {items.length > 0
            ? items.map((val) => (
            <WhatsNewItem key={val.extId} itemContent={val} />
            ))
            : <Empty/>}
        </WhatsNewWrapper>
      </Spin>
    </PageHeaderLayoutWithHeader>
  )
}
