import { Modal } from 'antd'
import styled from 'styled-components'

export const ModalWrapper = styled(Modal)`
  /* border: 1px solid red; */
  & .radio_group {
    //display: flex;
    justify-content: flex-start;
    gap: 18px;
   & .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    
}
.ant-radio-inner::after{
  background-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
}
  }
  & .label_text {
    color:  #343434;
    /* font-family: Roboto; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  & .ant-modal-close-x {
    margin-top: 8px;
    font-size: 14px;
    color: ${({ theme }) => theme.primary_color || '#00b7c0'} !important;
  }
  & .ant-modal-content {
    padding: 18px 17px 16px 22px;
    width: 572px;
    & .content_wrapper {
      /* border:1px solid red; */
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  & .ant-modal-header {
    padding: 0 0 14px 0;
  }
  & .ant-modal-body {
    padding: 0;
    & .content_title {
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  & .ant-modal-footer {
    padding: 16px 0 0 0;
    /* padding-top:16px   */
  }
  & .footer_primary_btn {
    border-radius: 2px;
    background: ${({ theme }) => theme.primary_color || '#00b7c0'} !important;
    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    padding: 5px 16px;
  }
  & .footer_btn {
    padding: 5px 16px;
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.primary_color || '#00b7c0'} !important;
    /* background: #fff; */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    &:hover {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'} !important;
    }
  }
`
