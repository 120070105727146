/* eslint-disable no-debugger */

export default function getSearchFilters (data) {
  if (!data) return
  const parsedData = JSON.parse(data)
  const filters = {
    current_title: { mustHave: [], mustNotHave: [] },
    expertise: { mustHave: [], mustNotHave: [] },
    degree: { mustHave: [], mustNotHave: [] },
    certifications: { mustHave: [], mustNotHave: [] },
    current_company: { mustHave: [], mustNotHave: [] },
    current_industry: { mustHave: [], mustNotHave: [] },
    career_count: { mustHave: [], mustNotHave: [] },
    career_length: { mustHave: [], mustNotHave: [] },
    postCode: { mustHave: [], mustNotHave: [] },
    location: { mustHave: [], mustNotHave: [] },
    distance: { mustHave: [], mustNotHave: [] },
    languages: { mustHave: [], mustNotHave: [] },
    school: { mustHave: [], mustNotHave: [] }
  }
  const mustHave = parsedData.mustHave
  const mustNotHave = parsedData.mustNotHave

  const shouldHave = parsedData.shouldHave

  for (const keys in mustHave) {
    if (filters[keys]) {
      filters[keys].mustHave = parsedData.mustHave[keys]
    }
  }

  for (const keys in mustNotHave) {
    if (filters[keys]) {
      filters[keys].mustNotHave = parsedData.mustNotHave[keys]
    }
  }

  for (const keys in shouldHave) {
    if (filters[keys]) {
      filters[keys].shouldHave = parsedData.shouldHave[keys]
    }
  }

  // eslint-disable-next-line
  const { current_title, expertise, degree, certifications, current_company, current_industry, career_length, career_count, location, postCode, distance, languages,school  } = filters
  return {
    title: { current_title },
    skills: { expertise },
    education: { degree, certifications, school },
    company: { company: current_company, industry: current_industry },
    experience: { years: career_length, count: career_count },
    location: { postCode, distance, location },
    languages

  }
}
