import React from 'react'
import { PropTypes } from 'prop-types'
import 'quill/dist/quill.snow.css'
import { DescriptionModalStyle, buttonStyleProps } from './style'
function DescriptionModal ({ isModalOpen, handleModal, itemContent }) {
  const { title, description, videoLink } = itemContent
  return (
    <DescriptionModalStyle
      title={title}
      destroyOnClose
      centered
      visible={isModalOpen}
      onCancel={() => {
        handleModal(false)
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: buttonStyleProps }}
      cancelText={'Close'}
      preserve={false}
      width={737}
    >
      <div className="ql-snow">
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
      {videoLink && (
        <div className="video">
          <iframe
            width="100%"
            height="100%"
            src={videoLink}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube Video"
          ></iframe>
        </div>
      )}
    </DescriptionModalStyle>
  )
}

export default DescriptionModal

DescriptionModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleModal: PropTypes.func,
  itemContent: PropTypes.object
}
