/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Layout,
  message,
  Row,
  Typography,
  Button,
  notification,
  Modal,
} from "antd";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { ReactComponent as ExportIcon } from "../../../assets/export.svg";
import Filters from "./Filters";
import FilteredData from "./FilteredData";
import HeaderLayout from "../../../layouts/HeaderLayout";
import ModalLayout from "../components/ModalLayout";
import { QUERY_ACTIONS } from "../components/Constants";
import { useAuthState } from "../../../contexts/AuthContext";
import {
  useQueryActions,
  useQuerysState,
  useQuerysDispatch,
  setIsLoading,
  setFields,
  setSavedSearchVal,
} from "../CandidateContext/SearchQueryContext";
// Styles
import "./searchedCandidates.css";
import classNames from "classnames";
import { ButtonsWrapper } from "../../../components/theme-components";
import { matchPath } from "react-router";
import SimpleSearchFilter from "./SimpleSearchFilter/SimpleSearchFilter";
import getSearchFilters from "./Filters/helpers/getSearchFilters";
import { useTranslation } from "react-i18next";
import { allConstants } from "../../../constants/constants";
import {
  fileDownloadDate,
  generateFileName,
  handleConfirmResumeForPassive,
} from "../../../helper";
import { useParams } from "react-router";
import fileDownload from "js-file-download";
import CandidateExportModal from "../../../components/CandidateExportModal/CandidateExportModal";
import { useToggle } from "ahooks";
const { confirm } = Modal;
const { Title } = Typography;
export default function FilteredCandidates({ match }) {
  // State
  const [existedFilters, setExistedFilters] = React.useState(undefined);
  const [isOpen, setIsOpen] = React.useState(false);
  const [updateModal, setUpdateModal] = React.useState(false);
  // const [isSave, setIsSave] = React.useState(false)
  const [loading, { toggle }] = useToggle(false)
  const [advancedSearchVal, setAdvanceSearchVal] = React.useState(false);
  const { jobkey } = match.params;
  const { t } = useTranslation();
  // Context
  const actions = useQueryActions();
  const dispatch = useQuerysDispatch();
  const { searchApiObj } = useQuerysState();
  const [candidateExportModal, setCandidateExportModal] = useState(false);
  const { user } = useAuthState();
  const { title } = useParams();
  const {
    candidatesFilters,
    SelectedCandidates,
    isLoading,
    searchedCandidates,
  } = useQuerysState();
  const { name: jobTitle } = candidatesFilters || { name: title };
  // Refs
  const DownloadRef = React.useRef();

  const simpleSearchPath = matchPath(location.pathname, {
    path: "/scoreui/simplesearch/:title/:skills/:location",
    exact: true,
    strict: false,
  });
  const savedSearchPath = matchPath(location.pathname, {
    path: "/scoreui/candidates/:jobkey",
    exact: true,
    strict: false,
  });
  const getActiveSearchQuery = async () => {
    try {
      await actions.jobPreview(jobkey);
      await actions.getSearchedCandidates(jobkey);
    } catch (e) {
      console.log(e);
    }
  };

  const HeaderTitle = (isOpen, setIsOpen, candidatesFilters) => {
    const updateSearch = () => {
      // setIsOpen(!isOpen)
      setUpdateModal(!updateModal);
    };
    const handleOk = (name, desc) => {
      const reqHeader = candidatesFilters;
      if (reqHeader) {
        const annotations = JSON.parse(reqHeader.searchJson);
        reqHeader.annotations = annotations;
        reqHeader.description = desc || "Description";
        reqHeader.name = name;
        actions.updateJob(reqHeader);
      }
      setIsOpen(false);
      setUpdateModal(!updateModal);
    };
    return (
      <Row>
        {candidatesFilters?.name ? (
          <Title level={4} className="filteredDataTitle">
            {candidatesFilters?.name}
            <EditOutlined
              onClick={updateSearch}
              style={{ marginLeft: "6px" }}
            />
          </Title>
        ) : (
          ""
        )}
        {updateModal && (
          <ModalLayout
            isOpen={true}
            handleOk={handleOk}
            setIsopen={setUpdateModal}
            isDescription={false}
            isLoading={isLoading}
            modalBody={QUERY_ACTIONS.EDIT}
            updateSearch={candidatesFilters}
          />
        )}
      </Row>
    );
  };

  const handleCandidateModal = (val) => {
    setCandidateExportModal(val);
  };

  const handleOkExport = async (csvPayload) => {
    try {
     toggle()
      const response = await actions.exportCandidates(
        {},
        {
          resultIds: SelectedCandidates,
          resultSetId: searchedCandidates?.resultSetId,
          ...csvPayload,
        }
      );
      if (csvPayload.withCv) {
        message.success("You will receive an email with the link shortly.");
      } else {
        const fileName=generateFileName(
          "passive-candidates-exports-for",
          jobTitle
        )
        fileDownload(response.data, fileName);
      }
      dispatch(setFields({ SelectedCandidates: [], setIsLoading: false }))   
      handleCandidateModal(false);
      window.analytics.track("exported selected", {
        search_id: jobkey,
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      toggle()
    }
  };

  const handleOk = () => {
    setIsOpen(false);
    handleCandidateModal(true);
  };
  const exportCandidate = () => {
    const exportCandidateModal = () => {
      if (SelectedCandidates.length < 1 || isLoading)
        return setTimeout(
          message.warning(`${t(allConstants.SELECT_ROW_TO_EXPORT)}...`, 0),
          1000
        );
      else if (user?.showConsumptionPopups) {
        setIsOpen(true);
      } else {
        // handleExportCandidates()
        handleCandidateModal(true);
      }
    };

    return (
      <>
        <a href="#" ref={DownloadRef} />
        <Button
          type="primary"
          id="export-selected"
          icon={<ExportOutlined />}
          onClick={exportCandidateModal}
        >
          {t(allConstants.EXPORT_SELECTED)}
        </Button>
      </>
    );
  };

  const FilteredDataStats = () => {
    return (
      <Row
        className={classNames(
          isLoading ? "disabled-stats candidate-stats" : "candidate-stats"
        )}
      >
        <Row>
          <span>Very Qualified</span>
          <span> {searchedCandidates?.group1} </span>
        </Row>
        <span className="stat-divider" />
        <Row>
          <span> Qualified</span>
          <span> {searchedCandidates?.group2} </span>
        </Row>
        <span className="stat-divider" />
        <Row>
          <span> Semi Qualified</span>
          <span> {searchedCandidates?.group3} </span>
        </Row>
        <span className="stat-divider" />
        <Row>
          <span> Least Qualified</span>
          <span> {searchedCandidates?.group4} </span>
        </Row>
      </Row>
    );
  };
  React.useEffect(() => {
    setAdvanceSearchVal(false);
    if (!simpleSearchPath) {
      getActiveSearchQuery();
    }
  }, []);

  return (
    <>
      <CandidateExportModal
        handleOk={handleOkExport}
        handleCandidateModal={handleCandidateModal}
        visible={candidateExportModal}
        title={t(allConstants.EXPORT_PASSIVE)}
        loading={loading}
      />
      <HeaderLayout
        title={
          simpleSearchPath ? (
            !advancedSearchVal ? (
              t(allConstants.SEARCH_RESULTS)
            ) : (
              <div className="saving-search-container">
                <span>{t(allConstants.SEARCH_RESULTS)}</span>
                <div
                  className={
                    isLoading ? "disable-save-search" : "saving-search"
                  }
                  onClick={() => dispatch(setSavedSearchVal(true))}
                >
                  <SaveOutlined style={{ marginRight: "8px" }} />
                  <span>{t(allConstants.SAVE_THIS_SEARCH)}</span>
                </div>
              </div>
            )
          ) : (
            HeaderTitle(isOpen, setIsOpen, candidatesFilters)
          )
        }
        // extra={exportCandidate()}
        isFooterHide={true}
      >
        <Layout id="searchedCandidatesLayout">
          {savedSearchPath && <Filters />}
          {simpleSearchPath && !advancedSearchVal ? (
            <SimpleSearchFilter setAdvanceSearchVal={setAdvanceSearchVal} />
          ) : (
            !savedSearchPath && <Filters />
          )}
          <FilteredData
            extra={exportCandidate()}
            advancedSearchVal={advancedSearchVal}
          />
          {/*        <FilteredDataStats  /> */}
        </Layout>

        {isOpen && (
          <ModalLayout
            isOpen={true}
            handleOk={handleOk}
            setIsopen={setIsOpen}
            isLoading={isLoading}
            modalBody={QUERY_ACTIONS.EXPORT_CANDIDATES}
          />
        )}
      </HeaderLayout>
    </>
  );
}

FilteredCandidates.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobkey: PropTypes.string,
    }),
  }),
};
