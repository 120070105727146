import React, { useEffect, useState } from 'react'
import { useRequest, useToggle } from 'ahooks'
import { useParams } from 'react-router'
import { useJobRequisitionsState, useJobRequisitionsDispatch, useJobRequisitionsActions, setSelectedCandidatesIds, setFilter } from '../../../context/ApplicantsContextProvider'
import FilterCard from '../FilterCard/FilterCard'
import { useJobsDispatch, useJobsState, setPassive, setPage } from '../../../../jobs/context/JobsContext'
// import { useAuthState } from '../../../../../contexts/AuthContext'

export default function PassiveCandidatesCard () {
  const [isV2Call, setIsV2Call] = useState(false)
  const { jobExtId, jobPostExtId } = useParams()
  const { passive: { loading, stats, list, resultSetId }, passiveLoading } = useJobRequisitionsState()
  const dispatch = useJobRequisitionsDispatch()
  const actions = useJobRequisitionsActions()
  const [isPaid, { toggle: togglePaid }] = useToggle()
  const { passive, page } = useJobsState()
  const jobsDispatch = useJobsDispatch()
  // const { user } = useAuthState()
  // const { run } = useRequest(() => actions.searchByJobPosting(jobExtId, jobPostExtId), { manual: true })
  const { run: runCachedResult } = useRequest((isV2Passive) => actions.getCachedResults(resultSetId, isV2Passive),
    {
      manual: true,
      onError: () => {} // override global onError
    })

  useEffect(() => {
    sessionStorage
      .setItem(
        `passive candidates already fetched: ${jobExtId}, ${jobPostExtId}`,
        isPaid ? 'PAID' : 'NOT-PAID'
      )
  }, [isPaid, jobExtId, jobPostExtId])

  useEffect(() => {
    if (passive) { verifyIfAlreadyPaidForPassiveCandidate() }
  }, [passive])

  useEffect(() => {
    if (resultSetId && passive) { showPromiseConfirm() }
  }, [resultSetId])

  const name = 'passive'

  const verifyIfAlreadyPaidForPassiveCandidate = async () => {
    dispatch(setSelectedCandidatesIds([]))
    if (sessionStorage.getItem(`passive candidates already fetched: ${jobExtId}, ${jobPostExtId}`) === 'PAID') {
      try {
        await runCachedResult()
        dispatch(setFilter(name))
      } catch (error) {
        sessionStorage.setItem(`passive candidates already fetched: ${jobExtId}, ${jobPostExtId}`, 'NOT-PAID')
        togglePaid(false)
        showPromiseConfirm()
      }
    } else {
      showPromiseConfirm()
    }
  }

  async function showPromiseConfirm () {
    // if (isV2) {
    await runCachedResult(true)
    setIsV2Call(true)
    // } else { await run() }
    dispatch(setFilter(name))
    jobsDispatch(setPassive(false))
    jobsDispatch(setPage(!page))
  }

  return (
    <>
      <FilterCard
        onClick={!isV2Call && verifyIfAlreadyPaidForPassiveCandidate}
        name={'passive'}
        title={(
          <>
            {/* <Tag color="blue" style={{ fontSize: 16, fontWeight: 'normal' }}>DISCOVER</Tag> */}
            <div>Passive Candidates</div>
          </>
        )}
        trackcard="Passive Candidates"
        value={(stats?.totalHits || list?.length || 0).toLocaleString()}
        tooltip="Passive Candidates come from our network of partner databases, composed of over 160 million individuals. Since they didn’t apply directly, these people may require a bit more effort to get a hold of"
        loading={ loading || passiveLoading }
      />
    </>
  )
}
