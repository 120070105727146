/* eslint-disable no-debugger */
import React, { useState } from 'react'
import { WhatsNewItemWrapper } from './style'
import { PropTypes } from 'prop-types'
import 'quill/dist/quill.snow.css'
import DescriptionModal from '../DescriptionModal/DescriptionModal'
import Calendar from '../../../../assets/Calendar.png'
import { dateInCardFormat } from '../../../../helper'

function WhatsNewItem ({ itemContent }) {
  const [descriptionModal, setDescriptionModal] = useState(false)

  const handleModal = (val) => {
    setDescriptionModal(val)
  }
  const {
    title,
    description,
    newPost = false,
    videoLink,
    createdAt,
    thumbnailUrl
  } = itemContent

  return (
    <>
      <WhatsNewItemWrapper onClick={() => setDescriptionModal(true)}>
        {videoLink && (
          <div>
            {<img className='thumbnail' src={thumbnailUrl} alt="YouTube Thumbnail" />}
          </div>
        )}
        <div className='item_header'>
          <div className='header_left'>
            <h2 className="title">
              {title}
              <span className={`${newPost ? 'show_new' : 'hide_new'}`}>
                New
              </span>
            </h2>
            <div className="ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          </div>
          <div className='created_date'>
              <img src={Calendar}></img> <span>{dateInCardFormat(createdAt)}</span>
          </div>
        </div>
      </WhatsNewItemWrapper>
      <DescriptionModal
        itemContent={itemContent}
        handleModal={handleModal}
        isModalOpen={descriptionModal}
      />
    </>
  )
}

WhatsNewItem.propTypes = {
  itemContent: PropTypes.object
}
export default WhatsNewItem
