import { Modal } from 'antd'
import styled from 'styled-components'

export const DescriptionModalStyle = styled(Modal)`
  .ql-snow {
    width: 92%;
  }
  .ql-snow{
    max-height:35vh;
    overflow-y: auto;

  }
  .ant-modal-content {
    max-width: 737px;
    padding: 0;
    border-radius: 0;
  }
  .ant-modal-close-x {
    line-height: 56px;
  }

  .ant-modal-header {
    padding: 16px;
    .ant-modal-title {
      font-size: 15px !important;
      font-weight: 600;
      color: #000;
    }
  }

  .ant-modal-body {
    padding: 16px;
    .video {
      margin-top: 24px;
      border-radius: 2.925px;
      padding: 11.699px;
      width: 432px;
      height: 253px;
      background-color: #e9f5f3;
      iframe {
        background-color: black;
        border: none;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  .ant-modal-footer {
    padding: 16px 0;
    margin: 0 16px;
  }
`
export const buttonStyleProps = {
  borderRadius: '2px',
  border: '1px solid #00B7C0',
  background: '#FFF',
  boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
  color: '#00B7C0',
  textAlign: 'center',
  fontFamily: 'Segoe UI',
  fontSize: ' 14px'
}
