/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, Radio, message } from 'antd'
// import { ExclamationCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { allConstants } from '../../constants/constants'
import fileDownload from 'js-file-download'
import { ModalWrapper } from './modalStyle'
import { useTranslation } from 'react-i18next'
const { EXPORT, CANCEL, HOW_TO_EXPORT, EXPORT_EMAIL, EXPORT_NOW } = allConstants
const CandidateExportModal = ({ title, handleOk, visible, handleCandidateModal, loading }) => {
  const [csvPayload, setCsvPayload] = useState({ withCv: false })
  const { t } = useTranslation()
  const [radioVal, setRadioVal] = useState('download')
  const handleChange = (e) => {
    const { value } = e.target
    setRadioVal(value)
    if (value === 'download') {
      setCsvPayload({ withCv: false })
    } else {
      setCsvPayload({ withCv: true })
    }
  }

  return (
    <ModalWrapper
      title={title}
      visible={visible}
      okText={t(EXPORT)}
      cancelText="Cancel"
      onCancel={() => { handleCandidateModal(false) }}
      wrapClassName="confirmPassiveModal"
      footer={[
       <Button className='footer_btn' key={1} onClick={() => {
         handleCandidateModal(false)
         setRadioVal('download')
       }
       }>
          {t(CANCEL)}
        </Button>, <Button loading={loading} className='footer_primary_btn' type='primary' key={2} onClick={() => handleOk(csvPayload)}>
          {t(EXPORT)}
        </Button>]}

    >
      <div className='content_wrapper'>
        <p className='content_title'>{t(HOW_TO_EXPORT)}</p>

      <Radio.Group
         className='radio_group'
         defaultValue={radioVal}
         value={radioVal}
        onChange={handleChange}
      >
        <Radio value={'email'} className="label_text">{t(EXPORT_EMAIL)}</Radio>
        <Radio value={'download'} className="label_text">{t(EXPORT_NOW)}</Radio>
      </Radio.Group>
      </div>
    </ModalWrapper>
  )
}

CandidateExportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCandidateModal: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool
}

export default CandidateExportModal
