import { useCallback, useState } from 'react'

const useGet = () => {
  const [values, setValues] = useState({
    response: null,
    error: null,
    loading: false
  })

  const { response, error, loading } = values

  const handleGet = useCallback((action) => {
    setValues((prev) => ({ ...prev, loading: true }))
    Promise.resolve(action())
      .then((res) => setValues({ response: res, error: null, loading: false }))
      .catch((err) =>
        setValues({ response: null, error: err, loading: false })
      )
  }, [])
  if (error) console.log(error)

  return {
    handleGet,
    response,
    error,
    loading
  }
}

export default useGet
