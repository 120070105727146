import { Modal } from 'antd'
import styled from 'styled-components'

export const WhatsNewItemWrapper = styled.div`
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  background: #fff;
  margin-top: 10px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 26px;
  .thumbnail{
    width: 140px;
    height:78.5px;
  }
  :hover {
    background: #f1feff;
    cursor: pointer;
  }
  .item_header{
    display: flex;
    justify-content: space-between;
    width:100%;

  }
  .created_date{
    span{
      color:#343434
    }
    img{
      margin-top: -6px;
      width:16px;
      height:16px
    }
  }
  .header_left{
    width:75%;
  }
  .video {
    width: 140px;
    height: 78.5px;
    align-self: flex-start;
  }
  .title {
    color: #00b7c0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-top: 6px;
  }
  .ql-snow,
  .ql-editor p {
    cursor: pointer !important;
  }
  .content {
    color: #343434;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 69%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }
  .show_new {
    border-radius: 2px;
    background: #e9f5f3;
    padding: 3px 8px;
    color: #343434;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 10px;
  }
  .hide_new {
    display: none;
  }
  .click_here {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    text-decoration: underline;
    color: #00b7c0;
  }
 
  @media screen and (min-width: 1400px) {
    .content {
      width: 80%;
    }
  }
`
export const CustomModal = styled(Modal)`
  width: 565px !important;
  top: 60px !important;

  & .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-modal-content {
    border-radius: 0 !important;
  }
  .ant-modal-body {
    border-radius: 0 !important;
    padding: 16px !important;
  }
  .modal_title {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 22px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-modal-close-x {
    font-size: 12px !important;
    margin-top: 2px;
    color: #343434;
  }
  & .ant-modal-close-icon {
    width: 100%;
    height: 100%;
    margin-left: 10px;
  }

  .modal_video {
    width: 410px;
    height: 230px;
    margin-top: 26px;
    margin-bottom: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .modal_content {
    width: 90%;
    color: #343434;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
  .footer_close_btn {
    margin-top: 16px;
    border-radius: 2px;
    border: 1px solid #00b7c0;
    background: #fff;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: #00b7c0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`

export const CustomDivider = styled.div`
  background: #f0f0f0;
  height: 1px;
  width: 100%;
`
